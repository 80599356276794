import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import EmbeddedVideo from '../EmbeddedVideo';

import styles from './transcriptions.module.css';

const Transcription = ( { pdf, source, title, video } ) => {
	return (
		<div className={ styles.transcription }>
			<div>
				<EmbeddedVideo videoID={ video } />
			</div>
			<div className={ styles.details }>
				<span>
					<h2 className={ styles.title }>{ title }</h2>
					<h4 className={ styles.source }>{ source }</h4>
				</span>
				<a className="more-link" download href={ pdf.publicURL }><small><FontAwesomeIcon icon={ faFilePdf } />&nbsp;&nbsp;download</small></a>
			</div>
		</div>
	);
};

Transcription.propTypes = {
	pdf: PropTypes.string.isRequired,
	source: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	video: PropTypes.string.isRequired
};

export default Transcription;
