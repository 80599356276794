import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Transcription from './Transcription';

import styles from './transcriptions.module.css';

const Transcriptions = ( { transcriptions } ) => {
	const mapTranscriptions = useCallback( () => transcriptions.map( transcription => <Transcription key={ transcription.title } { ...transcription } /> ), [ transcriptions ] );

	return (
		<div className={ styles.transcriptions }>
			{ mapTranscriptions() }
		</div>
	);
};

Transcriptions.propTypes = { transcriptions: PropTypes.array.isRequired };

export default Transcriptions;
