import React from 'react';
import PropTypes from 'prop-types';

import styles from './embedded-video.module.css';

const EmbeddedVideo = ( { videoID } ) => {
	return (
		<div className={ styles.container }>
			<iframe
				className={ styles.youtubeVideo }
				type="text/html"
				width="2280px"
				height="1280px"
				src={ `https://www.youtube.com/embed/${ videoID }?&rel=0&iv_load_policy=0&modestBranding=1` }
				allowFullScreen
				style={{ border: 0 }}
			/>
		</div>
	);
};

EmbeddedVideo.propTypes = { videoID: PropTypes.string.isRequired };

export default EmbeddedVideo;
