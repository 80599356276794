import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './hero.module.css';

const Hero = ( { alignment, fluid } ) => {
	return (
		<Img
			className={ styles.hero }
			fluid={ fluid }
			imgStyle={ { objectPosition: `center ${ alignment }` } }
		/>
	);
};

Hero.propTypes = {
	alignment: PropTypes.oneOf( [ 'center', 'top' ] ),
	fluid: PropTypes.object
};

export default Hero;
